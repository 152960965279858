<template>
  <div class="HongKongTrust">
    <div class="banner">
      <!-- <img width="100%" src="../assets/banner5.png" alt="" /> -->
      <div class="swiper-container-banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img width="100%" src="../assets/20230109172656.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img width="100%" src="../assets/20230109172720.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img width="100%" src="../assets/20230109172727.jpg" alt="" />
          </div>
        </div>
        <div
          class="swiper-button-next"
          style="color: #ffffff; margin-right: 37px"
        ></div>
        <div
          class="swiper-button-prev"
          style="color: #ffffff; margin-left: 20px"
        ></div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="title">
        <div class="h1">HONG KONG TRUST</div>
      </div>
      <!-- <div class="min_background"></div> -->
    </div>
    <div class="headline" id="FiveCoreAdvantages">Five Core Advantages</div>
    <div class="FiveCoreAdvantagespc">
      <!-- style="margin-top: 17px" -->
      <div class="left">
        <div
          v-for="item in FiveCoreAdvantages"
          :key="item.index"
          :class="{ active: item.index === data }"
          style="cursor: pointer"
          @click="handletitle(item.index)"
        >
          <p>{{ item.title }}</p>
        </div>
        <!-- <div>
          <p>Over a Century of History</p>
          <p>
            Hong Kong is benefited By The Advantages of International Financial
            Centre
          </p>
        </div>
        <div><p>Sophisticated Legal System</p></div>
        <div><p>Sound Supervision by Hong Kong SAR Government</p></div>
        <div><p>The Trust Account of Hong Kong is Permanent</p></div> -->
        <!-- <img src="../assets/HongKongTrustSwper2.jpg" alt="" /> -->
      </div>
      <div class="right">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img
                width="100%"
                src="../assets/HongKongTrustSwper1.png"
                alt=""
              />
              <div v-html="FiveCoreAdvantages[0].text"></div>
            </div>
            <div class="swiper-slide">
              <img
                width="100%"
                src="../assets/HongKongTrustSwper2.jpg"
                alt=""
              />
              <div v-html="FiveCoreAdvantages[1].text"></div>
            </div>
            <div class="swiper-slide">
              <img
                width="100%"
                src="../assets/HongKongTrustSwper3.png"
                alt=""
              />
              <div v-html="FiveCoreAdvantages[2].text"></div>
            </div>
            <div class="swiper-slide">
              <img
                width="100%"
                src="../assets/HongKongTrustSwper4.png"
                alt=""
              />
              <div v-html="FiveCoreAdvantages[3].text"></div>
            </div>
            <div class="swiper-slide">
              <img
                width="100%"
                src="../assets/HongKongTrustSwper5.jpg"
                alt=""
              />
              <div v-html="FiveCoreAdvantages[4].text"></div>
            </div>
          </div>
        </div>
        <div class="pc">
          <img width="100%" :src="FiveCoreAdvantages[data].img" alt="" />
        </div>
        <div class="copywriting">
          <div v-html="FiveCoreAdvantages[data].text"></div>
        </div>
      </div>
    </div>
    <div class="FiveCoreAdvantagesmin">
      <div class="m_headline" id="FiveCoreAdvantages">Five Core Advantages</div>
      <div v-for="(item, index) in FiveCoreAdvantages" :key="index">
        <img width="100%" :src="FiveCoreAdvantages[index].img" alt="" />
        <div class="FiveCoreAdvantagesminTitle">
          {{ FiveCoreAdvantages[index].title }}
        </div>
        <div class="text" v-html="FiveCoreAdvantages[index].text"></div>
      </div>
    </div>
    <div class="FeaturesofHongKongTrustLaw" id="FeaturesofHongKongTrustLaw">
      <div class="imgs">
        <img src="../assets/FeaturesofHongKongTrustLaw1.jpg" alt="" />
        <div>Features of Hong Kong Trust Law</div>
      </div>
      <div class="m_imgs">
        <div>Features of<br />Hong Kong Trust Law</div>
        <div class="bottom"></div>
      </div>
      <div class="nav">
        <div class="left">
          <div class="content">
            <img src="../assets/HongKongTrust1.png" alt="" />

            <h2 style="">Stronger protection for beneficiaries</h2>
            <p>
              New amendment provides better protection to beneficiaries and
              their interests.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust4.png" alt="" />
            <h2>Trust in Hong Kong is perpetual</h2>
            <p>
              Hong Kong trusts have no expiry. Alternatively, settlors can still
              choose to specify a fixed trust period - a feature that only few
              trust jurisdictions can offer.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust6.png" alt="" />
            <h2>Hong Kong SAR government is stable</h2>
            <p>
              ◆
              <span
                >China’s “One Country, Two Systems” policy for Hong Kong is
                enshrined in the Joint Declaration between Britain and
                China.</span
              >
            </p>
            <!-- <p>
              ◆ Hong Kong itself has a Bill of Human Rights and is a signatory
              to most major international conventions on human rights.
            </p> -->
            <p>
              ◆
              <span
                >It has a stable political environment and a long tradition of
                respecting property rights.</span
              >
            </p>
          </div>

          <div class="content">
            <img src="../assets/HongKongTrust9.png" alt="" />
            <h2>International business and investment hub</h2>
            <p>
              ◆
              <span
                >Hong Kong is the gateway to China and the hub of international
                business in Asia.</span
              >
            </p>
            <p>
              ◆
              <span>
                Hong Kong has one of the best international airports in the
                world.
              </span>
            </p>
          </div>
        </div>
        <div class="center">
          <div class="content">
            <img src="../assets/HongKongTrust2.png" alt="" />
            <h2>Sophisticated judiciary of Hong Kong</h2>
            <p style="word-wrap: break-word; word-break: unset; display: flex">
              ◆
              <span style="display: inline-block; margin-left: 5px">
                Hong Kong is an attractive jurisdiction from which to administer
                trusts, with a judiciary experienced in trust law and
                professionals experienced in private client and public trusts
                wealth management services. This ensures a high level of
                confidence in the legal framework, competitive pricing and the
                protection of assets.
              </span>
            </p>
            <p style="word-wrap: break-word; word-break: unset; display: flex">
              ◆<span style="display: inline-block; margin-left: 5px">
                Hong Kong’s trust legislation originated in 1934 and is based on
                English trust law.</span
              >
            </p>
            <p style="word-wrap: break-word; word-break: unset; display: flex">
              ◆
              <span style="display: inline-block; margin-left: 5px"
                >Hong Kong applies the case law and judicial precedents of
                Commonwealth countries. This ensures high level of confidence in
                settlors.</span
              >
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust7.png" alt="" />
            <h2>Forced heirship protection</h2>
            <p>
              The forced heirship of a foreign jurisdiction is not applicable to
              the assets in the Hong Kong Trust.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust10.png" alt="" />
            <h2>Sophisticated investment infrastructure</h2>
            <p>
              ◆
              <span>Availability of a large pool of investment expertise.</span>
            </p>
            <p>
              ◆
              <span
                >It is the major centre for investment funds, innovative
                investment vehicles and IPO’s in Asia, well supported by
                professional trustees and fund services providers.</span
              >
            </p>
            <p>
              ◆
              <span
                >Hong Kong is strategically and geographically positioned to
                take advantage of the enormous growth prospects in Asia in the
                coming years.</span
              >
            </p>
          </div>
        </div>
        <div class="right">
          <div class="content">
            <img src="../assets/HongKongTrust3.png" alt="" />
            <h2>Enhancement of trustee’s default power</h2>
            <p>
              When there is no trust deed, trustees may rely upon the default
              power of trustees stated in Trust Ordinance to provide trust
              service.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust5.png" alt="" />
            <h2>English as business and legal language</h2>
            <p>
              English is the major business and legal language. English is
              widely used in commercial contracts and the judiciary of Hong
              Kong.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust8.png" alt="" />
            <h2>International standard professionals</h2>
            <p>
              Hong Kong has a pool of professionals in the financial service
              area such as trust service, accountancy, banking, investment,
              legal counselling, etc.
            </p>
          </div>
          <div class="content">
            <img src="../assets/HongKongTrust11.png" alt="" />
            <h2>A major financial centre</h2>
            <p>
              ◆<span>
                Hong Kong is one of the three major international financial
                centres in the world, along with New York and London.</span
              >
            </p>
            <p>
              ◆
              <span>
                Hong Kong has access to a wide range of world-class banks and
                has one of the world’s major stock exchanges.
              </span>
            </p>
            <p>
              ◆
              <span>
                Hong Kong is a party to a wide range of international Tax
                Treaties.
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="imgs">
        <img src="../assets/FeaturesofHongKongTrustLaw2.png" alt="" />
      </div> -->

      <div class="nav">
        <div class="left"></div>
        <div class="center"></div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import Banner from '../components/Banner.vue'
import Swiper from "swiper/bundle"; // 引入swiper.js
import "swiper/swiper-bundle.min.css"; // 引入swiper.css
export default {
  data() {
    return {
      data: 0,
      FiveCoreAdvantages: [
        {
          title: "Over a century of history",
          index: 0,
          text: "<p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'> Hong Kong Trust has a long history of over 100 years of the trustees in the territory during the period.</span></p>",
          img: "https://www.hongkongtrust.com.hk/vue/img/HongKongTrustSwper1.f41432e6.png",
        },
        {
          title:
            "Hong Kong is benefited by the advantages of international financial centre",
          index: 1,
          text: "<p  style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆<span style ='display: inline-block;margin-left: 5px;'>  As an International Financial Centre in Asia, Hong Kong is a hub of wealth management.</p></span><p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆<span style ='display: inline-block;margin-left: 5px;'> Hong Kong has a global vision, rich financial market information and diversified trust products.</span></p><p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆<span> Hong Kong has a sophisticated banking service, no foreign exchange control.</span></p><p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>Rich expertise in financial service.</span></p>",
          img: "https://www.hongkongtrust.com.hk/vue/img/HongKongTrustSwper2.53c740cf.jpg",
        },
        {
          title: "Sophisticated Legal System",
          index: 2,
          text: "<p  style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>Once a trust is set up in Hong Kong, the asset in the trust account will be protected under the law of Hong Kong and free from interference of foreign jurisdiction regardless of the nationality of the settlor.</span></p><p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>As registration is not required for Hong Kong trust, the privacy of settlor is thus effectively secured.</span></p><p style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>“Hong Kong Privacy Ordinance” protects the privacy of settlor’s personal data. Trustees will not disclose such information to any country or institution under the Ordinance.</span></p>",
          img: "https://www.hongkongtrust.com.hk/vue/img/HongKongTrustSwper3.f319cc84.png",
        },
        /* <p>◆ Given a long history of over 100 years from 1903, Hong Kong Trust Law is comprehensive and sophisticated.</p> */
        {
          title: "Sound supervision by Hong Kong SAR government",
          index: 3,
          text: "<p  style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>Trustees registered in Hong Kong are under the cap.29, Trustee Ordinance.</span></p><p  style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>The operation of Hong Kong trustees is under the supervision of various authorities such as the Financial Secretary of the Hong Kong SAR Government, Securities and Futures Commission, Hong Kong Federation of Insurance simultaneously depending on the asset involved in the trust.</span></p>",
          img: "https://www.hongkongtrust.com.hk/vue/img/HongKongTrustSwper4.f241408e.png",
        },
        {
          title: "The trust of Hong Kong is permanent",
          index: 4,
          text: "<p  style = 'overflow-wrap: break-word;word-break: unset;display: flex;'>◆ <span style ='display: inline-block;margin-left: 5px;'>According to 2013 Hong Kong Trust Law (Amendment) Ordinance, the trust of Hong Kong is valid permanently whereas there is an expiry of trust in most Common Law jurisdictions (e.g. Malaysia - 80 years; Singapore - 100 years; United Kingdom - 125 years). So, Hong Kong Trust can provide asset safety, capital growth and inheritance of legacy without a time limit while the trust of other jurisdictions provides the same service in a limited period of time.</span></p>",
          img: "https://www.hongkongtrust.com.hk/vue/img/HongKongTrustSwper5.aee1da77.jpg",
        },
      ],
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      // 如果需要分页器
      loop: true,
      speed: 300,
      autoplay: {
        delay: 2000,
      },
    });
    new Swiper(".swiper-container-banner", {
      loop: true,
      speed: 300,
      autoplay: {
        delay: 3000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },
  methods: {
    handletitle(data) {
      this.data = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-container {
  display: none;
}
.banner {
  width: 100vw;
  /* background: url("../assets/banner5.png") no-repeat;
  background-size: cover; */
  position: relative;
  .min_background {
    display: none;
    background: #fff;
    height: 17px;
    position: absolute;
    width: 84vw;
    margin-left: 8vw;
    bottom: 0;
  }
}
.title {
  color: #fff;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  font-size: 55px;
}
.headline {
  font-size: 30px;
  font-weight: inherit;
  font-family: "Georgia";
  margin-top: 26px;
  margin-bottom: 0px;
}
.FiveCoreAdvantagespc {
  width: 60vw;
  margin: 0px auto;
  margin-top: 40px;
  display: flex;
  font-family: contert;
  justify-content: space-between;
  .left {
    font-size: 20px;
    width: 42%;
    font-family: Georgia;
    .active {
      background-color: rgb(172, 159, 159);
      p {
        padding: 10px 10px;
        color: #fff;
        font-family: Georgia;
      }
    }
    div {
      padding-top: 25px;
      border-top: 1px solid #000;
      padding-bottom: 25px;
      text-align: left;
      p {
        margin: 0px;
      }
    }
  }
  .right {
    width: 54%;
    .pc {
      overflow: hidden;
      img {
        transition: all 0.6s;
        cursor: pointer;
      }
      img:hover {
        transform: scale(1.3);
      }
    }
    .copywriting {
      text-align: left;
      font-size: 17px;
      div {
        text-align: inherit;
      }
      p {
        margin: 40px auto;
      }
    }
  }
}
//  .left div:nth-of-type(1){
//     font-family: Georgia;
//  }
.FiveCoreAdvantagesmin {
  display: none;
}
.FeaturesofHongKongTrustLaw {
  width: 100vw;
  margin: 0px auto;
  font-family: "Georgia";
  margin-bottom: -144px;
  .m_imgs {
    display: none;
  }
  .imgs {
    margin-top: 64px;
    position: relative;
    div {
      position: absolute;
      width: 100vw;
      top: 40%;
      font-size: 38px;
      // font-family: "FZZJ"; //标题字体
      color: #fff;
    }
    img {
      width: 100%;
    }
  }
  .nav {
    width: 60vw;
    margin: 38px auto;
    display: flex;
    text-align: left;
    justify-content: space-between;
    margin-top: 34px;
    // margin-top: 100px;
    // font-family: FZZJ; //内容字体
    .content {
      background-color: #f2f2f2;
      padding-bottom: 20px;
      margin-bottom: 50px;
      overflow: hidden;
    }
    img {
      width: 100%;
      transition: all 0.6s;
      cursor: pointer;
    }
    .content:hover {
      img {
        transform: scale(1.1);
      }
    }
    h2 {
      margin: 20px auto 20px;
      width: 90%;
      font-size: 20px;
      font-weight: 400;
      /* border-top: 1px solid #000; */
      padding-top: 10px;
      font-family: "Georgia";
      border-top: 2px solid gray;
    }
    p {
      font-size: 16px;
      width: 90%;
      text-align: start;
      font-weight: 300;
      margin: 0 auto;
      font-family: "contert";
      overflow-wrap: break-word;
      word-break: unset;
      display: flex;
    }
    span {
      display: inline-block;
      margin-left: 5px;
    }
    .left {
      width: 31%;
    }
    .right {
      width: 31%;
    }
    .center {
      width: 31%;
    }
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .home .title .h1 {
    font-size: 48px;
  }
  .headline {
    margin-top: 50px;
  }
  .FiveCoreAdvantagespc {
    width: 80vw;
    margin-top: 50px;
  }
  .FeaturesofHongKongTrustLaw .nav {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title {
    font-size: 36px;
  }
  .headline {
    font-size: 20px;
    margin-top: 30px;
  }
  .FiveCoreAdvantagespc {
    margin-top: 40px;
    width: 80vw;
    .right .copywriting {
      font-size: 14px;
    }
    .left {
      div {
        padding-bottom: 10px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .FeaturesofHongKongTrustLaw .imgs div {
    font-size: 20px;
  }
  .FeaturesofHongKongTrustLaw {
    margin-bottom: 70px;
    .nav {
      width: 80vw;
      margin-top: 20px;
      h2 {
        font-size: 20px;
        margin: 30px auto 15px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 767px) {
  // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .swiper-button-next {
    display: none;
  }
  .swiper-button-prev {
    display: none;
  }
  .swiper-pagination {
    display: none;
  }
  .title {
    font-size: 30px;
    // font-family: "webfont2";
    top: 50%;
  }
  .headline {
    margin-top: 20px;
    font-size: 20px;
    display: none;
  }
  /* .swiper-container {
    display: block;
    .swiper-slide {
      div {
        text-align: justify;
      }
    }
  } */
  .banner {
    margin-top: 64px;
    .min_background {
      display: block;
    }
  }
  .nav {
    display: none;
  }
  .FiveCoreAdvantagespc {
    display: none;
  }
  .FiveCoreAdvantages {
    width: 90vw;
    margin-top: 10px;
    flex-wrap: wrap;
    .left {
      width: 100%;
      font-size: 10px;
      /* display: none; */
      div {
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }
    .right {
      /* display: none; */
      width: 100%;
      .copywriting {
        font-size: 12px;
      }
    }
  }
  .FiveCoreAdvantagesmin {
    width: 84vw;
    margin: 0 auto;
    display: block;
    .m_headline {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #5d5d5d;
      margin-bottom: 7px;
    }
    .FiveCoreAdvantagesminTitle {
      text-align: left;
      margin: 14px 0px 0px;
      font-size: 16px;
      font-weight: 500;
      color: #5d5d5d;
    }
    .text {
      /* border-bottom: 1px solid; */
      color: #000;
      font-family: "contert";
    }
    div {
      text-align: initial;
      font-size: 14px;
    }
  }
  .FeaturesofHongKongTrustLaw {
    margin-bottom: -30px;
    background: #093f8d;
    .m_imgs {
      display: block;
      padding-top: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      .bottom {
        width: 30%;
        margin: 10px auto 0;
        height: 2px;
        background: #fff;
      }
    }
    .imgs {
      margin-top: 20px;
      display: none;
      div {
        font-size: 20px;
        top: 30%;
      }
    }
    .nav {
      width: 84vw;
      flex-direction: column;
      margin-top: 28px;
      .content {
        margin-bottom: 15px;
        padding-bottom: 15px;
        background: none;
        color: #fff;
      }
      h2 {
        font-size: 16px;
        margin: 10px auto 10px;
        padding-top: 5px;
        width: 100%;
        line-height: 1.8;
      }
      p {
        width: 100%;
        font-size: 14px;
        margin: 0px auto;
      }
      .left {
        width: 100%;
      }
      .center {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
}
</style>
<style>
.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}
.swiper-pagination-bullet-active {
  background: #ffffff;
}
</style>
